import {
  IS_PENDING,
  IS_REJECTED,
  SET_DOWNLOAD_DATA,
} from "../types/backblazeTypes";

function pbReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case IS_PENDING: {
      return {
        ...state,
        isPending: true,
        isRejected: false,
      };
    }

    case IS_REJECTED: {
      return {
        ...state,
        isPending: false,
        isRejected: true,
      };
    }

    case SET_DOWNLOAD_DATA: {
      const { data } = payload;
      const { authToken, downloadUrl } = data;

      return {
        ...state,
        authToken,
        downloadUrl,
        isPending: false,
      };
    }

    default:
      return state;
  }
}

export default pbReducer;
