import React, { useEffect, useReducer } from "react";
import axios from "axios";
import cartReducer from "../reducers/cartReducer";
import { IS_PENDING, SET_CART, IS_REJECTED } from "../types/cartTypes";
import { useUser } from "./userContext";

const CartContext = React.createContext();

const initialState = {
  isPending: true,
  isRejected: false,
  error: null,
  cart: null,
};

function CartProvider(props) {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const user = useUser();
  const { _id: userId } = user || {};

  useEffect(() => {
    let isCancelled = false;

    function fetchData() {
      dispatch({ type: IS_PENDING });
      axios
        .get("/api/cart")
        .then((res) => {
          if (!isCancelled) {
            dispatch({
              type: SET_CART,
              payload: { cart: res.data },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: IS_REJECTED,
            payload: { error: err.response },
          });
        });
    }

    if (userId) {
      fetchData();
    }

    return () => {
      isCancelled = true;
    };
  }, [userId]);
  return <CartContext.Provider value={{ state, dispatch }} {...props} />;
}

function useCart() {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`);
  }
  return context;
}

export { CartProvider, useCart };
