import {
  SET_CART,
  IS_PENDING,
  IS_REJECTED,
  ADD_ITEM_TO_CART,
  EDIT_CART_ITEM,
  DELETE_CART_ITEM,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAILED,
} from "../types/cartTypes";

function cartReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case IS_PENDING: {
      return {
        ...state,
        isPending: true,
        isRejected: false,
      };
    }

    case IS_REJECTED: {
      return {
        ...state,
        isPending: false,
        isRejected: true,
      };
    }

    case SET_CART: {
      const { cart } = payload;

      return {
        ...state,
        cart,
        isRejected: false,
        isPending: false,
      };
    }

    case ADD_ITEM_TO_CART: {
      const { values, itemId, photoBook } = payload;
      const { cart } = state;

      let newItem = {
        ...values,
        _id: itemId,
        photoBook,
      };

      cart.items.push(newItem);

      return {
        ...state,
        cart,
      };
    }

    case EDIT_CART_ITEM: {
      const { itemId, values } = payload;
      const { cart } = state;
      const { items } = cart;

      const itemIndex = items.map((x) => x._id).indexOf(itemId);

      items[itemIndex] = {
        ...items[itemIndex],
        ...values,
      };

      return {
        ...state,
      };
    }

    case DELETE_CART_ITEM: {
      const { itemId } = payload;
      const { cart } = state;
      const { items } = cart;

      const itemIndex = items.map((x) => x._id).indexOf(itemId);

      items[itemIndex] = {
        ...items[itemIndex],
        isPending: true,
        isRejected: false,
      };

      return {
        ...state,
      };
    }

    case DELETE_CART_ITEM_SUCCESS: {
      const { itemId } = payload;
      const { cart } = state;
      const { items } = cart;

      const itemIndex = items.map((x) => x._id).indexOf(itemId);

      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
      }

      return {
        ...state,
      };
    }

    case DELETE_CART_ITEM_FAILED: {
      const { itemId, err } = payload;
      const { cart } = state;
      const { items } = cart;

      const itemIndex = items.map((x) => x._id).indexOf(itemId);

      items[itemIndex] = {
        ...items[itemIndex],
        isPending: false,
        isRejected: true,
        error: err,
      };

      return {
        ...state,
      };
    }

    default:
      return state;
  }
}

export default cartReducer;
