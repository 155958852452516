import React from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

import { Facebook, Instagram } from "./NavSvgs";

const Footer = () => {
  const { width } = useWindowSize();
  return (
    <footer
      className="web-footer"
      style={{
        padding: width > 1100 ? "50px 80px" : "30px",
      }}
    >
      <div className="web-footer-content">
        <div className="footer-social-media-container web-footer-social-media-container">
          <h4 className="secondary-font">
            Want to be the first to know?
            <br />
            Follow us on social:
          </h4>
          <div className="web-social-media-links-wrapper flex-center">
            {/* TODO: Add href */}
            <SocialMediaLink to="/" svg={<Facebook />} aria-label="Facebook" />
            <SocialMediaLink
              to="/"
              svg={<Instagram />}
              aria-label="Instagram"
            />
          </div>
        </div>

        <div className="footer-link-wrapper web-footer-link-wrapper oswald">
          <Link to="/faq">
            <span>FAQ</span>
          </Link>
          <Link to="/shipping">
            <span>Shipping</span>
          </Link>
          <Link to="/return-policy">
            <span>Return & Refund Policy</span>
          </Link>
          <Link to="/terms">
            <span>Terms of Use</span>
          </Link>
          <Link to="/privacy">
            <span>Privacy Policy</span>
          </Link>
          <Link to="/contact-us">
            <span>Contact Us</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

const SocialMediaLink = ({ to, svg, label }) => {
  return (
    <Link to={to} className="web-footer-social-link" aria-label={label}>
      {svg}
    </Link>
  );
};

export default Footer;
