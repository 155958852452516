import React from "react";
import useMobile from "../../hooks/useMobile";

import WebFooter from "./WebFooter";
import MobileFooter from "./MobileFooter";

const Footer = () => {
  const isMobile = useMobile();

  if (isMobile) {
    return <MobileFooter />;
  } else {
    return <WebFooter />;
  }
};

export default Footer;
