import React, { lazy, Suspense } from "react";
import useMobile from "../../hooks/useMobile";

import "./navigation.css";

const WebNavigation = lazy(() => import("./WebNavigation"));
const MobileNavigation = lazy(() => import("./MobileNavigation"));

const Navigation = () => {
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <Suspense fallback={null}>
        <MobileNavigation />
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={null}>
        <WebNavigation />
      </Suspense>
    );
  }
};

export default Navigation;
