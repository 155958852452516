import React from "react";
import { Link } from "react-router-dom";
import { Instagram, Facebook } from "./NavSvgs";

const Footer = () => {
  return (
    <footer className="mobile-footer oswald">
      <div className="mobile-footer-upper-content">
        <div className="footer-link-wrapper mobile-footer-link-wrapper oswald">
          <div>
            <Link to="/faq">FAQ</Link>
            <Link to="/shipping">Shipping</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
          <div>
            <Link to="/terms">Terms of Use</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/return-policy">Return & Refund Policy</Link>
          </div>
        </div>
      </div>
      <div className="mobile-footer-social-media-container footer-social-media-container">
        <h4 className="secondary-font">
          Want to be the first to know?
          <br />
          Follow us on social:
        </h4>
        <div className="mobile-social-media-links-wrapper flex-center">
          {/* TODO: Add href */}
          <SocialMediaLink to="/" svg={<Facebook />} aria-label="Facebook" />
          <SocialMediaLink to="/" svg={<Instagram />} aria-label="Instagram" />
        </div>
      </div>
    </footer>
  );
};

const SocialMediaLink = ({ to, svg, label }) => {
  return (
    <Link to={to} aria-label={label}>
      {svg}
    </Link>
  );
};

export default Footer;
