export const IS_PENDING = "IS_PENDING";
export const IS_REJECTED = "IS_REJECTED";
export const SET_CART = "SET_CART";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const ADD_ITEM_TO_CART_SUCCESS = "ADD_ITEM_TO_CART_SUCCESS";
export const ADD_ITEM_TO_CART_FAILED = "ADD_ITEM_TO_CART_FAILED";
export const EDIT_CART_ITEM = "EDIT_CART_ITEM";
export const EDIT_CART_ITEM_SUCCESS = "EDIT_CART_ITEM_SUCCESS";
export const EDIT_CART_ITEM_FAILED = "EDIT_CART_ITEM_FAILED";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const DELETE_CART_ITEM_SUCCESS = "DELETE_CART_ITEM_SUCCESS";
export const DELETE_CART_ITEM_FAILED = "DELETE_CART_ITEM_FAILED";
