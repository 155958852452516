import React, { useEffect, useReducer } from "react";
import axios from "axios";
import backblazeReducer from "../reducers/backblazeReducer";
import {
  IS_PENDING,
  SET_DOWNLOAD_DATA,
  IS_REJECTED,
} from "../types/backblazeTypes";
import { useUser } from "./userContext";

const BackblazeContext = React.createContext();

const initialState = {
  isPending: true,
  isRejected: false,
  error: null,
  downloadUrl: null,
  authToken: null,
};

function BackblazeProvider(props) {
  const [state, dispatch] = useReducer(backblazeReducer, initialState);
  const user = useUser();

  const { _id: userId, applicationKeyId } = user || {};

  useEffect(() => {
    let isCancelled = false;

    function fetchData() {
      dispatch({ type: IS_PENDING });
      axios
        .get("/api/backblaze/download")
        .then((res) => {
          if (!isCancelled) {
            dispatch({
              type: SET_DOWNLOAD_DATA,
              payload: { data: res.data },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: IS_REJECTED,
            payload: { error: err.response },
          });
        });
    }

    if (userId && applicationKeyId) {
      fetchData();
    }

    return () => {
      isCancelled = true;
    };
  }, [userId, applicationKeyId]);

  return <BackblazeContext.Provider value={{ state, dispatch }} {...props} />;
}

function useBackblaze() {
  const context = React.useContext(BackblazeContext);

  if (context === undefined) {
    throw new Error(`useBackblaze must be used within a BackblazeContext`);
  }
  return context;
}

export { BackblazeProvider, useBackblaze };
