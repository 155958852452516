import { useRef, useState, useEffect, useReducer } from "react";
import axios from "axios";
import userReducer from "../reducers/userReducer";
import { SET_USER, IS_PENDING, IS_REJECTED } from "../types/userTypes";

const initialState = {
  user: null,
  isPending: true,
  isRejected: false,
  error: null,
};

const useUser = () => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const [reload, setReload] = useState(0);
  const timesTried = useRef(1);
  const [setKeys, setSetKeys] = useState(false);

  const { user } = state;

  useEffect(() => {
    return () => {
      timesTried.current = 1;
    };
  }, []);

  const updateUser = () => {
    setReload(reload + 1);
  };

  const setAppKeys = () => {
    setSetKeys(true);
  };

  useEffect(() => {
    let isCancelled = false;

    function fetchData() {
      dispatch({ type: IS_PENDING });
      axios
        .get(`/api/user/me`)
        .then((res) => {
          if (!isCancelled) {
            dispatch({ type: SET_USER, payload: { user: res.data } });
          }
        })
        .catch((err) => {
          const { response } = err;
          if (response) {
            var { status } = response;
          }
          if (status === 403) {
            axios
              .get("/api/user/id")
              .then((res) => {
                if (!isCancelled) {
                  dispatch({ type: SET_USER, payload: { user: res.data } });
                }
              })
              .catch((err) => {
                if (!isCancelled) {
                  dispatch({
                    type: IS_REJECTED,
                    payload: { error: err.response },
                  });
                }
              });
          } else {
            if (!isCancelled) {
              dispatch({
                type: IS_REJECTED,
                payload: { error: err.response },
              });
            }
          }
        });
    }

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [reload]);

  useEffect(() => {
    let isCancelled = false;

    function createAppKeys() {
      axios
        .get(`/api/user/create-keys`)
        .then((res) => {
          if (!isCancelled) {
            dispatch({ type: SET_USER, payload: { user: res.data } });
          }
        })
        .catch((err) => {
          const { response } = err;
          if (response) {
            var { status } = response;
          }
          if (status === 403) {
            axios
              .get("/api/user/create-keys")
              .then((res) => {
                if (!isCancelled) {
                  dispatch({ type: SET_USER, payload: { user: res.data } });
                }
              })
              .catch((err) => {
                if (!isCancelled) {
                  dispatch({
                    type: IS_REJECTED,
                    payload: { error: err.response },
                  });
                }
              });
          } else {
            if (!isCancelled) {
              dispatch({
                type: IS_REJECTED,
                payload: { error: err.response },
              });
            }
          }
        });
    }

    if (user && setKeys && !user.applicationKey) {
      createAppKeys();
    }
    return () => {
      isCancelled = true;
    };
  }, [setKeys, user]);

  return {
    state,
    dispatch,
    updateUser,
    setAppKeys,
  };
};

export default useUser;
