import {
  SET_USER,
  IS_PENDING,
  IS_REJECTED,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
} from "../types/userTypes";

function userReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case IS_REJECTED: {
      const { error } = payload;
      return {
        ...state,
        user: null,
        isRejected: true,
        isPending: false,
        error,
      };
    }
    case IS_PENDING: {
      return {
        ...state,
        isPending: true,
        isRejected: false,
        error: null,
      };
    }
    case SET_USER: {
      const { user } = payload;

      user.isSignedIn = false;

      if (user.email) {
        user.isSignedIn = true;
      }

      return {
        ...state,
        user,
        error: null,
        isPending: false,
      };
    }

    case EDIT_USER_SUCCESS: {
      const { values } = payload;
      const { user } = state;
      const newUser = {
        ...user,
        ...values,
      };

      return {
        ...state,
        user: newUser,
      };
    }

    case EDIT_USER_FAILED: {
      const { image } = payload;
      const { user } = state;
      const newUser = {
        ...user,
      };

      if (image) {
        newUser.imagePending = false;
        newUser.imageRejected = true;
      } else {
        newUser.isPending = false;
        newUser.isRejected = true;
      }

      return {
        ...state,
        user: newUser,
      };
    }

    default:
      return state;
  }
}

export default userReducer;
