import React, { useEffect, useReducer, createContext } from "react";
import loadingReducer from "../reducers/loadingReducer";

const LoadingContext = createContext();

const initialState = {
  isLoading: true,
};

function LoadingProvider({ children }) {
  const [state, dispatch] = useReducer(loadingReducer, initialState);

  const { isLoading } = state;

  useEffect(() => {
    const loader = document.querySelector(".loader");
    const showLoader = () => loader.classList.remove("loader--hide");
    const hideLoader = () => loader.classList.add("loader--hide");
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading]);

  return (
    <LoadingContext.Provider
      value={{ state, dispatch }}
      state={state}
      dispatch={dispatch}
    >
      {children}
    </LoadingContext.Provider>
  );
}

function useLoading() {
  const context = React.useContext(LoadingContext);

  if (context === undefined) {
    throw new Error(`useLoading must be used within a LoadingProvider`);
  }
  return context;
}

export { LoadingProvider, useLoading };
