import React, { lazy, Suspense, useState, useLayoutEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import useWindowSize from "./hooks/useWindowSize";
import useMobile from "./hooks/useMobile";

import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Navigation/Footer";

import "./App.css";

const Home = lazy(() => import("./components/Home/Home"));
const FAQ = lazy(() => import("./components/Misc/FAQ"));
const Shipping = lazy(() => import("./components/Misc/Shipping"));
const ReturnPolicy = lazy(() => import("./components/Misc/ReturnPolicy"));
const ContactUs = lazy(() => import("./components/Misc/ContactUs"));
const Terms = lazy(() => import("./components/Terms/Terms"));
const Privacy = lazy(() => import("./components/Terms/Privacy"));
const Login = lazy(() => import("./components/Misc/Login"));
const NoMatch = lazy(() => import("./components/Misc/NoMatch"));
const PhotoBookRouter = lazy(() => import("./PhotoBookRouter"));

const Account = lazy(() => import("./components/Account/Account"));
const AccountOrder = lazy(() => import("./components/Account/AccountOrder"));
const Cart = lazy(() => import("./components/Cart/Cart"));

const Checkout = lazy(() => import("./components/Checkout/DinteroCheckout"));
const CheckoutReturn = lazy(() =>
  import("./components/Checkout/CheckoutReturn")
);

const Logout = lazy(() => import("./components/Misc/Logout"));

function Router() {
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [margin, setMargin] = useState("61px 0 431px");
  const location = useLocation();
  const isMobile = useMobile();
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    const { pathname } = location;

    const headerPages = [
      new RegExp(/^\/order\/.*/),
      new RegExp(/^\/cart/),
      new RegExp(/^\/faq/),
      new RegExp(/^\/shipping/),
      new RegExp(/^\/return-policy/),
      new RegExp(/^\/contact-us/),
      new RegExp(/^\/terms/),
      new RegExp(/^\/privacy/),
    ];
    const footerHeaderPages = [
      new RegExp(/^\/$/),
      new RegExp(/^\/account.*/),
      new RegExp(/^\/faq/),
      new RegExp(/^\/shipping/),
      new RegExp(/^\/return-policy/),
      new RegExp(/^\/contact-us/),
      new RegExp(/^\/terms/),
      new RegExp(/^\/privacy/),
    ];

    const isFooterHeaderPage =
      footerHeaderPages.map((x) => x.test(pathname)).indexOf(true) > -1;
    const isHeaderPage =
      headerPages.map((x) => x.test(pathname)).indexOf(true) > -1;

    if (isFooterHeaderPage) {
      setShowHeader(true);
      setShowFooter(true);

      if (isMobile) {
        setMargin("60px 0 283px");
      } else if (width > 1100) {
        setMargin("70px 0 242px");
      } else {
        setMargin("70px 0 202px");
      }
    } else if (isHeaderPage) {
      setShowHeader(true);
      setShowFooter(false);

      if (isMobile) {
        setMargin("60px 0 0");
      } else {
        setMargin("70px 0 0");
      }
    } else {
      setShowHeader(false);
      setShowFooter(false);

      setMargin("0");
    }
  }, [isMobile, location, width]);

  return (
    <div
      className="root-wrapper"
      style={{
        margin,
      }}
    >
      {showHeader && <Navigation />}
      <Suspense fallback={null}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/return-policy" component={ReturnPolicy} />
          <Route exact path="/shipping" component={Shipping} />
          <Route
            exact
            path="/photo-book/standard"
            component={PhotoBookRouter}
          />
          <Route
            exact
            path="/photo-book/add-pictures/:photobook_id"
            component={PhotoBookRouter}
          />
          <Route
            exact
            path="/editor/:photobook_id"
            component={PhotoBookRouter}
          />
          <Route
            exact
            path="/order/:photobook_id"
            component={PhotoBookRouter}
          />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/checkout/:purchase_id" component={Checkout} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/account" component={Account} />
          <Route
            exact
            path="/account/order/:photobook_id"
            component={AccountOrder}
          />

          <Route
            exact
            path="/checkout/return/:purchase_id"
            component={CheckoutReturn}
          />

          <Route component={NoMatch} />
        </Switch>
      </Suspense>
      {showFooter && <Footer />}
    </div>
  );
}

export default Router;
