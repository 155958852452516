import { useState, useLayoutEffect } from "react";

function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  useLayoutEffect(() => {
    function updateWH() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateWH);
    updateWH();
    return () => window.removeEventListener("resize", updateWH);
  }, []);
  return { height, width };
}

export default useWindowSize;
